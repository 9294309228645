import { ErrorCode } from '@packages/ui';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { PageLayout } from '@/components/PageLayout';
import { Box } from '@chakra-ui/react';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <Box minHeight={400} pt={'10vh'}>
        <ErrorCode errorCode={404} errorMessage={t('Page not found')} />
      </Box>
    </PageLayout>
  );
};

export default NotFound;
